<script lang="ts" setup>
import { notifySentry } from '@/helpers/notifySentry'
import {useResetPasswordController} from '@eli5/vue-auth'

const emit = defineEmits<{
  (e: 'success'): void
  (e: 'login'): void
}>()
const route = useRoute()
const queryToken = typeof route.query.token === 'string' ? route.query.token : ''
const {handleSubmit, errors, password, repeat_password, isLoading} = useResetPasswordController({ token: queryToken })

const handleLogin = async () => {
  try {
    await handleSubmit()
    emit('success')
  } catch (e) {
    notifySentry(e)
  }
}
</script>

<template>
  <form @submit.stop.prevent="handleLogin">
    <h3>{{ $t('reset.title') }}</h3>
    <DefaultInput
      v-model="password"
      type="password"
      autofocus
      :placeholder="$t('forgot.password')"
      autocomplete="new-password"
      :disabled="isLoading ?? undefined"
      :error="errors?.password?.join(', ') ?? undefined"
    />
    <DefaultInput
      v-model="repeat_password"
      type="password"
      :placeholder="$t('forgot.password')"
      autocomplete="new-password"
      :disabled="isLoading ?? undefined"
      :error="errors?.message?.join(', ') ?? errors?.repeat_password?.join(', ') ?? undefined"
    />
    <button class="button primary" type="submit" :disabled="isLoading ?? undefined">{{ isLoading ? $t('reset.loading') : $t('reset.reset')}}</button>
    <button class="reset" type="button" @click="emit('login')">{{$t('reset.login')}}</button>
  </form>
</template>

<style lang="postcss" scoped>
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: var(--container-gap);
  width: 30rem;
  max-width: 50vw;
  & h3 {
    margin: 0;
  }

  & button[type='button'] {
    justify-content: center;
  }
}
</style>
