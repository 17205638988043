<script lang="ts" setup>
import {useLoginController} from '@eli5/vue-auth'
import { notifySentry } from '@/helpers/notifySentry'
import {AuthUserDetailResponse} from '@/store/useAuthStore'

const emit = defineEmits<{
  (e: 'success'): void
  (e: 'forgot'): void
}>()
const {handleSubmit, errors, email, password, isLoading} = useLoginController<AuthUserDetailResponse>({
  typeGuard: AuthUserDetailResponse,
})

const handleLogin = async () => {
  try {
    await handleSubmit()
    emit('success')
  } catch (e) {
    notifySentry(e)
  }
}
</script>

<template>
  <form @submit.stop.prevent="handleLogin">
    <h3>{{$t('login.title')}}</h3>
    <DefaultInput
      v-model="email"
      type="email"
      autocomplete="email"
      autofocus
      :placeholder="$t('login.email')"
      :disabled="isLoading ?? undefined"
      :error="errors?.email?.map((e) => $t(e)).join(', ') ?? undefined"
    />
    <DefaultInput
      v-model="password"
      type="password"
      :placeholder="$t('login.password')"
      autocomplete="current-password"
      :disabled="isLoading ?? undefined"
      :error="errors?.message?.map((e) => $t(e)).join(', ') ?? errors?.password?.map((e) => $t(e)).join(', ') ?? undefined"
    />
    <button class="button primary" type="submit" :disabled="isLoading ?? undefined">{{ $t(`login.${isLoading ? 'loading' : 'login'}`) }}</button>
    <button class="reset" type="button" @click="emit('forgot')">{{$t('login.forgot')}}</button>
  </form>
</template>

<style lang="postcss" scoped>
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: var(--container-gap);
  width: 30rem;
  max-width: 50vw;
  & h3 {
    margin: 0;
  }

  & button[type='button'] {
    justify-content: center;
  }
}
</style>
