<script lang="ts" setup>
const props = defineProps<{
  isOpen: boolean
}>()
const emit = defineEmits<{
  (e: 'update:isOpen', payload: boolean): void
}>()

const state = ref<'login' | 'forgot' | 'reset'>('login')

const goToForgot = () => state.value = 'forgot'
const goToLogin = () => state.value = 'login'
const goToReset = () => state.value = 'reset'

const updateIsOpen = (payload: boolean) => {
  emit('update:isOpen', payload)
}

onMounted(() => {
  const route = useRoute()
  if ('token' in route.query) {
    goToReset()
    updateIsOpen(true)
  }
})
</script>

<template>
  <ClientOnly>
    <DefaultModal :isOpen="isOpen" @update:isOpen="updateIsOpen" small>
      <LoginForm v-if="state === 'login'" @forgot="goToForgot" @success="updateIsOpen(false)" />
      <ForgotForm v-else-if="state === 'forgot'" @login="goToLogin" @success="goToLogin" />
      <ResetForm v-else-if="state === 'reset'" @login="goToLogin" @success="goToLogin" />
    </DefaultModal>
  </ClientOnly>
</template>

<style lang="postcss" scoped>
@import 'open-props/media';
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: var(--container-gap);
  width: 30rem;
  max-width: 50vw;
  & h3 {
    margin: 0;
  }

  & button[type='button'] {
    justify-content: center;
  }
}
</style>
