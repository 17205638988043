<script lang="ts" setup>
import {useForgotPasswordController} from '@eli5/vue-auth'
import { notifySentry } from '@/helpers/notifySentry'
const emit = defineEmits<{
  (e: 'success'): void
  (e: 'login'): void
}>()
const {handleSubmit, errors, email, isLoading} = useForgotPasswordController()

const handleForm = async () => {
  try {
    await handleSubmit()
    emit('success')
  } catch (e) {
    notifySentry(e)
  }
}
</script>

<template>
  <form @submit.stop.prevent="handleForm">
    <h3>{{$t('forgot.title')}}</h3>
    <DefaultInput
      v-model="email"
      type="email"
      autofocus
      :placeholder="$t('forgot.email')"
      autocomplete="email"
      :disabled="isLoading ?? undefined"
      :error="errors?.message?.join(', ') ?? errors?.email?.join(', ') ?? undefined"
    />
    <button class="button primary" type="submit" :disabled="isLoading ?? undefined">{{ $t(`forgot.${isLoading ? 'loading' : 'reset'}`) }}</button>
    <footer>
      <!-- <p>{{ $t('forgot.account') }}</p> -->
      <button class="reset" type="button" @click="emit('login')">{{ $t('forgot.login') }}</button>
    </footer>
  </form>
</template>

<style lang="postcss" scoped>
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: var(--container-gap);
  width: 30rem;
  max-width: 50vw;
  & h3 {
    margin: 0;
  }

  & button[type='button'] {
    justify-content: center;
  }
  & footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & p {
      margin: 0;
    }
  }
}
</style>
